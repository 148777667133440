<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encfac"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="confirmarencfac" title="Pregunta" appendTo="body"></p-confirmDialog>
<div class="layout-config">
	<div class="layout-config-content-wrapper">
		<a class="layout-config-button" id="layout-config-button" style="cursor: pointer" (click)="acciones()">
			<i class="pi pi-bars" style="cursor: pointer"></i>
		</a>
		<p-sidebar class="layout-config-content" position="right" [(visible)]="displayAcciones" [modal]="false"
			[dismissible]="true" [showCloseIcon]="false">
			<br>
			<br>
			<br>
			<p-tabView>
				<p-tabPanel header="Acciones">
					<div class="p-grid">
						<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 200px)'}">
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarPedido()"
									class="archivoOpc">Agregar Pedido</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarGuia()"
									class="archivoOpc">Agregar Guia</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarSeriales()"
									class="archivoOpc">Ingresar Seriales</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarFacturaAnulada()"
									class="archivoOpc">Activar Factura Anulada</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarReembolsos()"
									class="archivoOpc">Reembolsos</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarPorceService()"
									class="archivoOpc">Agregar % Servicio</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="verpedidospendientes()"
									class="archivoOpc">Ver Pedidos Pendientes</span>
							</div>
								<!-- CDPJ -->
								<div class="p-col-12">
									<i class="pi pi-caret-right archivoOpc"></i><span (click)="actbasesiva()"
										class="archivoOpc">Actualizar bases IVA</span>
								</div>
								<div class="p-col-12">
									<i class="pi pi-folder archivoOpc"></i><span (click)="verbasesiva()"
										class="archivoOpc">Visualizar bases IVA</span>
								</div>
								<!-- CDPJ -->
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="activarFacturaAnuladaFE()"
									class="archivoOpc">Activar boton de anular factura firmada electrónicamente</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="reversarFE()"
									class="archivoOpc">Reversar inicio de proceso de Firma Electrónica</span>
							</div>
							<div  *ngIf="boolfacturaElec === true" class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="continuarFE()"
									class="archivoOpc">Continuar proceso de firma electrónica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="refrescarDatosFE('acciones')"
									class="archivoOpc">Refrescar datos de factura electronica</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarLog()"
									class="archivoOpc">Visualizar log del documento</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="visualizarEst()"
									class="archivoOpc">Estados firma electrónica</span>
							</div>
							<hr />
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="abrirDatosVentaArticulo()"
									class="archivoOpc">Consultar ventas por artículo</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="insertarServicioSeguro()"
									class="archivoOpc">Aplicar seguro</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="disminuirPrecioIva()"
									class="archivoOpc">Desglosar el Iva del Item</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-folder archivoOpc"></i><span (click)="modificariva()"
									class="archivoOpc">Modificar Porcentaje del Iva</span>
							</div>
							<!-- CDPJ -->
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="aggTotdsctglo()"
									class="archivoOpc">Agregar el total al descuento global</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="reversaraggTotdsctglo()"
									class="archivoOpc">Quitar del valor del descuento global</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="distribuirInvLotes()"
									class="archivoOpc">Distribuir Lotes</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="reversarInvLotes()"
									class="archivoOpc">Reversar Distribución de Lotes</span>
							</div>
							<div class="p-col-12">
								<i class="pi pi-caret-right archivoOpc"></i><span (click)="agregarcomentarioInvLotes()"
									class="archivoOpc">Agregar lotes como comentario</span>
							</div>
							<!-- CDPJ -->
						</p-scrollPanel>
					</div>
				</p-tabPanel>
			</p-tabView>
		</p-sidebar>
	</div>
</div>
<div class="p-grid" style="width: 99%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="manejarSenales($event)" [barraBotones3]="barraBotones2" [barraBotonesAsiCon]="false"
			[botonBorrar]="botonBorrar" [botonNuevo]="botonNuevo" [botonGuardar]="botonGuardar"
			[botonRegresar]="botonRegresar" [botonPrimero]="!botonPrimero" [botonAnterior]="!botonAnterior"
			[botonSiguiente]="!botonSiguiente" [botonUltimo]="!botonUltimo" [barraBotones5]="false"
			[barraFactura]="false" [botonAnular]="botonAnular" [botonAsiento]="false" [botonverAsiento]="false"
			[botonPago]="false" [botonPagoCredito]="false" [botonBuscar]="false" [botonXML]="botonXML"
			[botonReenviarCorreo]="false" [botonAlmacenardoc]="true" ></app-invmaebarra>
	</div>
</div>
<div class="p-grid" style="margin-top: -10px; margin-bottom: -10px; width: 99%;">
	<!--LINEA 1-->
	<div class="p-col-2">
		<label for="ENCFAC_GRUPO"></label>
		<input class="frm" id="ENCFAC_GRUPO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_GRUPO"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-1">
		<label for="ENCFAC_ESTADO"></label>
		<input class="frm" id="ENCFAC_ESTADO" type="text" pInputText
			style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
			[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_ESTADO"
			(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-1">
		<div *ngIf="encfacService.encfacarray[indicador].ENCFAC_IMPRESO==='IMPRESA'">
			<label for="ENCFAC_IMPRESO"></label>
			<input class="frm" id="ENCFAC_IMPRESO" type="text" pInputText
				style="background-color: #EFFFF7; text-align: center; color: rgb(0,128,0); font-weight: bold"
				[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_IMPRESO"
				(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
		</div>
	</div>
	<div class="p-col-1">
	</div>
	<div class="p-col-1">
		<label for="ENCFAC_ESTADO_FE"></label>
		<input class="frm" id="ENCFAC_ESTADO_FE" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_ESTADO_FE" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFAC_AUTORIZACION_FE"></label>
		<input class="frm" id="ENCFAC_AUTORIZACION_FE" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_AUTORIZACION_FE" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<label for="ENCFAC_CLAVEACCESO_FE"></label>
		<input class="frm" id="ENCFAC_CLAVEACCESO_FE" type="text" pInputText
			style="background-color: rgb(40,138,210); font-weight: bold; color: white;" [disabled]="true"
			[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_CLAVEACCESO_FE" (keydown.enter)="setFocus($event)"
			(keydown)="botonesmodificar()" autocomplete="off">
	</div>
	<div class="p-col-2">
		<div class="p-grid">
			<div class="p-col-1"></div>
			<div class="p-col-8">
				<label for="ASI_NRO"></label>
				<input class="frm" id="ASI_NRO" type="text" pInputText style="background-color: #EFFFF7"
					[disabled]="true" [(ngModel)]="encfacService.encfacarray[indicador].ASI_NRO"
					(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
			</div>
		</div>
	</div>
</div>

<p-tabView [(activeIndex)]="index" (onChange)="changeTabs()" [style]="{'width':'98%'}">
	<p-tabPanel header="Factura">
		<p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 270px)'}">
			<div class="contorno" id="contorno">
				<div class="divgrups">
					<div class="p-grid">
						<!--LINEA 1-->
						<div class="p-col-1">
							<span>Número:</span>
						</div>
						<div class="p-col-2">
							<label for="ENCFAC_NUMERO"></label>
							<input class="frm" id="ENCFAC_NUMERO" type="text" pInputText
								[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_NUMERO"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<span>Autorización:</span>
						</div>
						<div class="p-col-1">
							<label for="ENCFAC_AUTORIZACION"></label>
							<input class="frm" id="ENCFAC_AUTORIZACION" type="text" pInputText
								[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_AUTORIZACION"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-1">
							<div *ngIf="CONSIDNV === 1">
								<p-checkbox name="notadeventa" value="" label="" binary="{{chknotadeventa}}"
									[(ngModel)]="chknotadeventa"></p-checkbox>
								<span>Nota de Venta</span>
							</div>
						</div>
						<div class="p-col-1">
							<span>Saldo Cliente:</span>
						</div>
						<div class="p-col-1">
							<label for="ENCFAC_SALDOCLIENTE"></label>
							<input class="frm" id="ENCFAC_SALDOCLIENTE" type="text" pInputText style="color: blue;"
								disabled [(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_SALDOCLIENTE"
								(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()" autocomplete="off">
						</div>
						<div class="p-col-4">
						</div>
						<!--LINEA 2-->
						<div class="p-col-12" style="margin-top: -7px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>F.Emisión: </span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_FECHAEMISION"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()"[disabled]="boolfeh">
									</p-calendar>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_HORAEMISION" [inputStyle]="{width: '80%', height: '20px'}"
										[timeOnly]="true"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_HORAEMISION"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()"[disabled]="boolfeh">
									</p-calendar>
								</div>
								<div class="p-col-1">
								</div>
								<div class="p-col-2">
									<div *ngIf="labelPromocion===true">
										<span>Revise Promociones</span>
									</div>
								</div>
								<div class="p-col-1">
									<span>Días Crédito:</span>
								</div>
								<div class="p-col-2">
									<label for="ENCFAC_DIASCRDT"></label>
									<input class="frm" id="ENCFAC_DIASCRDT" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_DIASCRDT"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off" [disabled]="bloqdiasfac">
								</div>
								<div class="p-col-1">
									<span>F.Vencimiento: </span>
								</div>
								<div class="p-col-1">
									<p-calendar id="ENCFAC_FECHAVENCIMIENTO"
										[inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_FECHAVENCIMIENTO"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
								</div>
							</div>
						</div>
						<!--LINEA 3-->
						<div class="p-col-12" style="margin-top: -13px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Cliente:</span>
								</div>
								<div class="p-col-1">
									<label for="CLI_CODIGO"></label>
									<input class="frm" id="CLI_CODIGO" type="text" pInputText [disabled]="bolCliente"
										[(ngModel)]="encfacService.encfacarray[indicador].CLI_CODIGO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedacliente(encfacService.encfacarray[indicador].CLI_CODIGO)"></button>
								</div>
								<div class="p-col-3">
									<label for="GRUP_NOMBRE"></label>
									<input class="frm" id="GRUP_NOMBRE" type="text" pInputText disabled
										style="color: blue;"
										[(ngModel)]="encfacService.encfacarray[indicador].CLI_NOMBRE"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>Listas Precio:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmblistasprecio"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selectlistasprecio"
										(onChange)="busqlistasprecio($event)"></p-dropdown>
								</div>
								<div class="p-col-3">
								</div>
							</div>
						</div>
						<!--LINEA 4-->
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Vendedor:</span>
								</div>
								<div class="p-col-1">
									<label for="VEN_CODIGO"></label>
									<input class="frm" id="VEN_CODIGO" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].VEN_CODIGO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<button type="button"
										style="background-image: url(../../../assets/images/iconos/buscar.png);"
										(click)="busquedavendedor(encfacService.encfacarray[indicador].VEN_CODIGO)"></button>
								</div>
								<div class="p-col-3">
									<label for="VEN_NOMBRE"></label>
									<input class="frm" id="VEN_NOMBRE" type="text" pInputText disabled
										[(ngModel)]="encfacService.encfacarray[indicador].VEN_NOMBRE"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<span>IVA:</span>
								</div>
								<div class="p-col-2">
									<p-dropdown [disabled]="encfacService.detfacarray[indicador].length !== 0"
										[options]="cmbivaaux" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectiva"
										(onChange)="busqiva($event)"></p-dropdown>
								</div>
								<div class="p-col-1">
									<span>%IVA:</span>
								</div>
								<div class="p-col-1">
									<!-- <p-dropdown id="ENCFAC_PORCEIVA" [options]="cmbporceiva"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selectporceiva"
										[disabled]="disablediva" (onChange)="busqporceiva($event)"></p-dropdown> -->
										<p-dropdown [options]="cmbporceiva" [style]="{'minWidth': '100%', 'width': '100%'}"
                                        optionLabel="name" 
                                        (onChange)="cambiavalorIva($event)"
										[(ngModel)]="selectcombtipoIva"
										[disabled]="disablediva"
							            ></p-dropdown> 

								</div>
								<div class="p-col-1">
								</div>
							</div>
						</div>
						<!--LINEA 5-->
						<div class="p-col-12" style="margin-top: -20px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Referencia:</span>
								</div>
								<div class="p-col-1">
									<label for="ENCFAC_REFERENCIA"></label>
									<input class="frm" id="ENCFAC_REFERENCIA" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_REFERENCIA"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-1">
									<div *ngIf="REFMAE === 1 || REFLOCMED === 1">
										<button type="button"
											style="background-image: url(../../../assets/images/iconos/buscar.png);"></button>
									</div>
								</div>
								<div class="p-col-3">
									<div *ngIf="REFMAE === 1 || REFLOCMED === 1">
										<label for="ENCFAC_REFERENCIANOMBRE"></label>
										<input class="frm" id="ENCFAC_REFERENCIANOMBRE" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_REFERENCIANOMBRE"
											autocomplete="off">
									</div>
								</div>
								<div class="p-col-1">
									<span>Bodega:</span>
								</div>
								<div class="p-col-3">
									<p-dropdown [options]="cmbbodega" [style]="{'minWidth': '100%', 'width':'100%'}"
										optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selectbodega"
										(onChange)="busqbodega($event)" [disabled]="bloqbdfac"></p-dropdown>
								</div>
								<div class="p-col-2">
									<p-dropdown [options]="cmbtipofactura"
										[style]="{'minWidth': '100%', 'width':'100%'}" optionLabel="name"
										[panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipofactura"
										(onChange)="busqtipofactura($event)"></p-dropdown>
								</div>
							</div>
						</div>
						<!--LINEA 6-->
						<div class="p-col-12" style="margin-top: -18px; margin-bottom: -15px">
							<div class="p-grid">
								<div class="p-col-1">
									<span>Comentario:</span>
								</div>
								<div class="p-col-5">
									<label for="ENCFAC_COMENTARIO"></label>
									<input class="frm" id="ENCFAC_COMENTARIO" type="text" pInputText
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_COMENTARIO"
										(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										autocomplete="off">
								</div>
								<div class="p-col-2">
									<div *ngIf="NUMITEMFAC > 0">
										<span>Items Disponibles:</span>
									</div>
								</div>
								<div class="p-col-1">
									<div *ngIf="NUMITEMFAC > 0">
										<label for="ENCFAC_ITEMSDISPONIBLES"></label>
										<input class="frm" id="ENCFAC_ITEMSDISPONIBLES" type="text" pInputText
											style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_ITEMSDISPONIBLES"
											[disabled]="true" (keydown.enter)="setFocus($event)"
											(keydown)="botonesmodificar()" autocomplete="off">
									</div>
								</div>
								<div class="p-col-1">
									<div *ngIf="PUNVTAS === 1">
										<span>Puntos:</span>
									</div>
								</div>
								<div class="p-col-1">
									<div *ngIf="PUNVTAS === 1">
										<label for="ENCFAC_PUNTOS"></label>
										<input class="frm" id="ENCFAC_PUNTOS" type="text" pInputText
											[(ngModel)]="encfacService.encfacarray[this.indicador].ENCFAC_PUNTOS"
											(keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
											autocomplete="off">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--<div id="contornodetalle">-->
			<app-aggridsaci (keydown.f4)="disminuirPrecioIva()" [width]="1300" [height]="largo" [enableSorting]="false"
				[enableFilter]="true" [rowData]="encfacService.detfacarray[indicador]"
				[frameworkComponents]="frameworkComponents" [animateRows]="true" [rowSelection]="rowSelection"
				[columnDefs]="columnDefsDetfac" [defaultColDef]="defaultColDef" [rowStyle]="rowStyle"
				[singleClickEdit]="false" [botones]="true" [botonNuevo]='bolNuevodet' (selected)="selecciondetfac($event)"
				(rdbtipodet)="rdbtipodet($event)" [comboOpciones]="comboOpciones" [tipodetalle]="tipodetalle"
				[mostrarGuardar]="false" [botonesAdicionales]="true" [botonFactura]="botonFactura"
				(cambios)="cambio($event)" [btnVervehiculo]="false"></app-aggridsaci>
			<!--</div>-->
			<div class="divgrupstabla">
				<div class="p-grid">
					<div class="p-col-6">
						<div class="p-grid">
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span class="obligatorio">Neto:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Descuento:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Base I.V.A.:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">I.V.A.:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Base I.C.E.:</span>
							</div>
						</div>
					</div>
					<div class="p-col-6">
						<div class="p-grid">
							<!-- <div class="p-col-1"></div> -->
							<div class="p-col-2">
								<span class="obligatorio">IRBPNR:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">I.C.E.:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Base Cero:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">B.No Imp.Iva:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">Servicio:</span>
							</div>
							<div class="p-col-2">
								<span class="obligatorio">TOTAL:</span>
							</div>
							<div class="p-col-2"></div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -32px">
						<div class="p-grid">
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<label for="ENCFAC_TOTALNETO"></label>
										<input class="totales" id="ENCFAC_TOTALNETO" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTALNETO"
											autocomplete="off">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_VALORDES"></label>
										<input class="totales" id="ENCFAC_VALORDES" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORDES"
											autocomplete="off">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASEIVA"></label>
										<input class="totales" id="ENCFAC_BASEIVA" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASEIVA"
											autocomplete="off">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_VALORIVA"></label>
										<input class="totales" id="ENCFAC_VALORIVA" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORIVA"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASEICE"></label>
										<input class="totales" id="ENCFAC_BASEICE" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASEICE"
											autocomplete="off" style="text-align: right">
									</div>
								</div>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<!-- <div class="p-col-1"></div> -->
									<div class="p-col-2">
										<label for="ENCFAC_VALORIRBPNR"></label>
										<input class="totales" id="ENCFAC_VALORIRBPNR" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORIRBPNR"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_VALORICE"></label>
										<input class="totales" id="ENCFAC_VALORICE" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_VALORICE"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASECERO"></label>
										<input class="totales" id="ENCFAC_BASECERO" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASECERO"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_BASENOOBJIVA"></label>
										<input class="totales" id="ENCFAC_BASENOOBJIVA" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_BASENOOBJIVA"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_SERVICIO"></label>
										<input class="totales" id="ENCFAC_SERVICIO" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_SERVICIO"
											autocomplete="off" style="text-align: right">
									</div>
									<div class="p-col-2">
										<label for="ENCFAC_TOTAL"></label>
										<input class="totales" id="ENCFAC_TOTAL" type="text" pInputText disabled
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTAL"
											autocomplete="off" style="text-align: right">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="p-grid" style="margin-top: -15px; margin-bottom: -15px">
					<div class="p-col-1">
						<span class="obligatorio">TotalCant.:</span>
					</div>
					<div class="p-col-1">
						<label for="ENCFAC_TOTALCANT"></label>
						<input class="totales" id="ENCFAC_TOTALCANT" type="text" pInputText disabled
							[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTALCANT" autocomplete="off">
					</div>
					<div class="p-col-1">
						<span class="obligatorio">PVP:</span>
					</div>
					<div class="p-col-1">
						<label for="ENCFAC_PVP"></label>
						<input class="totales" id="ENCFAC_PVP" type="text" pInputText disabled
							[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_PVP" autocomplete="off">
					</div>
					<div class="p-col-1">
						<span class="obligatorio">C.Física:</span>
					</div>
					<div class="p-col-1">
						<label for="ENCFAC_CFISICA"></label>
						<input class="totales" id="ENCFAC_CFISICA" type="text" pInputText disabled
							[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_CFISICA" autocomplete="off">
					</div>
					<div class="p-col-2">
						<div class="p-grid">
							<div class="p-col-5"
								style="margin-top: 10px; height: 25%; background-color: rgb(128, 128, 255)">
							</div>
							<div class="p-col-7">
								<span>Existencia Bajo Mín.</span>
							</div>
						</div>
					</div>
					<div class="p-col-2">
						<div class="p-grid">
							<div class="p-col-5"
								style="margin-top: 10px; height: 25%; background-color: rgb(255, 98, 98)">
							</div>
							<div class="p-col-7">
								<span>Precio Bajo Costo</span>
							</div>
						</div>
					</div>
					<div class="p-col-2">
						<div class="p-grid">
							<div class="p-col-5"
								style="margin-top: 10px; height: 25%; background-color: rgb(128, 255, 128)">
							</div>
							<div class="p-col-7">
								<span>Promociones</span>
							</div>
						</div>
					</div>
				</div> -->
				<div class="p-grid">
					<div class="p-col-6">
							<div class="p-grid">
								<div class="p-col-1"></div>
								<div class="p-col-2">
									<span class="obligatorio">TotalCant:</span>
								</div>
								<div class="p-col-2">
									<span class="obligatorio">PVP:</span>
								</div>
								<div class="p-col-2">
									<span class="obligatorio">C.Física:</span>
								</div>
								<div class="p-col-2">
									<span class="obligatorio">Otros Descuentos:</span>
								</div>
								<div class="p-col-2">
									<span></span>
								</div>
							</div>
						</div>
						<div class="p-col-6">
							<div class="p-grid">
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-5"
											style="margin-top: 10px; height: 25%; background-color: rgb(128, 128, 255)">
										</div>
										<div class="p-col-7">
											<span>Existencia Bajo Mín.</span>
										</div>
									</div>
								</div>
								<div class="p-col-4">
									<div class="p-grid">
										<div class="p-col-5"
											style="margin-top: 10px; height: 25%; background-color: rgb(255, 98, 98)">
										</div>
										<div class="p-col-7">
											<span>Precio Bajo Costo</span>
										</div>
									</div>
								</div>
								<div class="p-col-2">
									<div class="p-grid">
										<div class="p-col-5"
											style="margin-top: 10px; height: 25%; background-color: rgb(128, 255, 128)">
										</div>
										<div class="p-col-7">
											<span>Promociones</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="p-col-12" style="margin-top: -28px">
							<div class="p-grid">
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-1"></div>
										<div class="p-col-2">
											<label for="ENCFAC_TOTALCANT"></label>
											<input class="totales" id="ENCFAC_TOTALCANT" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TOTALCANT" autocomplete="off">
										</div>
										<div class="p-col-2">
											<label for="ENCFAC_PVP"></label>
											<input class="totales" id="ENCFAC_PVP" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_PVP" autocomplete="off">
										</div>
										<div class="p-col-2">
											<label for="ENCFAC_CFISICA"></label>
											<input class="totales" id="ENCFAC_CFISICA" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_CFISICA" autocomplete="off">
										</div>
										<div class="p-col-2">
											<label for="ENCFAC_OTRDSCTOS"></label>
											<input class="totales" id="ENCFAC_OTRDSCTOS" type="text" pInputText disabled
												[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_OTRDSCTOS"
												autocomplete="off">
										</div>
										<div class="p-col-2">
											
										</div>
									</div>
								</div>
								<div class="p-col-6">
									<div class="p-grid">
										<div class="p-col-5">
											<div class="p-grid">
												<div class="p-col-4"
													style="margin-top: 10px; height: 25%; background-color: rgb(234, 250, 93)">
												</div>
												<div class="p-col-8">
													<span>Total Incluido en el Descuento Global</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				</div>
			</div>
		</p-scrollPanel>
	</p-tabPanel>
	<p-tabPanel header="Estado de Cuenta" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span>{{encfacService.encfacarray[indicador].CLI_CODIGO + ' - ' + encfacService.encfacarray[indicador].CLI_NOMBRE}}</span>
				</div>
				<div class="p-col-2">
				</div>
				<!-- <div class="p-col-2">
					<button class="medium" style="background-image: url(../../assets/images/iconos/024-message.png)"
						(click)="enviarEstadoCuenta()">
					</button>
				</div> -->
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctmptrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctmptrnresumen"
			[defaultColDef]="defaultColDefCxctmptrnresumen" [rowStyle]="rowStyleEC" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Saldos" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
					<span>Cliente:</span>
				</div>
				<div class="p-col-6">
					<span>{{encfacService.encfacarray[indicador].CLI_CODIGO + ' - ' + encfacService.encfacarray[indicador].CLI_NOMBRE}}</span>
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
		<app-aggridsaci [width]="" [height]="largoestcta" [enableSorting]="true" [enableFilter]="true"
			[rowData]="cxctrnresumen" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsCxctrnresumen"
			[defaultColDef]="defaultColDefCxctrnresumen" [rowStyle]="rowStyleSaldo" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>
	</p-tabPanel>
	<p-tabPanel header="Datos Cliente" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>Nombre:</span>
				</div>
				<div class="p-col-4">
					<label for="DCNOMBRE"></label>
					<input class="frm" id="DCNOMBRE" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].CLI_NOMBRE" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>RUC/IDE.:</span>
				</div>
				<div class="p-col-4">
					<label for="DCRUCIDE"></label>
					<input class="frm" id="DCRUCIDE" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_RUCIDE" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>Dirección 1:</span>
				</div>
				<div class="p-col-4">
					<label for="DCDIRECCION1"></label>
					<input class="frm" id="DCDIRECCION1" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_DIRECCION" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>Dirección 2:</span>
				</div>
				<div class="p-col-4">
					<label for="DCDIRECCION2"></label>
					<input class="frm" id="DCDIRECCION2" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_DIRECCION2" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>Teléfono 1:</span>
				</div>
				<div class="p-col-1">
					<label for="DCTELEFONO1"></label>
					<input class="frm" id="DCTELEFONO1" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TELEFONO" autocomplete="off">
				</div>
				<div class="p-col-2">
					<span>Teléfono 2:</span>
				</div>
				<div class="p-col-1">
					<label for="DCTELEFONO2"></label>
					<input class="frm" id="DCTELEFONO2" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_TELEFONO2" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>Fax:</span>
				</div>
				<div class="p-col-4">
					<label for="DCFAX"></label>
					<input class="frm" id="DCFAX" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_FAX" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
				<div class="p-col-2">
				</div>
				<div class="p-col-2">
					<span>Correo Electrónico:</span>
				</div>
				<div class="p-col-4">
					<label for="DCCORREO"></label>
					<input class="frm" id="DCCORREO" type="text" pInputText disabled
						[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_CORREO" autocomplete="off">
				</div>
				<div class="p-col-4">
				</div>
			</div>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Información Adicional" [disabled]="botonNuevo && index<1">
		<div class="divgrups">
			<div class="p-grid">
				<!--LINEA 1-->
				<div class="p-col-8">
				</div>
				<div class="p-col-1">
					<button type="button" style="background-image: url(../../../assets/images/iconos/visto.png);"
						(click)="guardarDatosBloque()"></button>
				</div>
			</div>
		</div>

		<app-aggridsaci [width]="" [height]="largo" [enableSorting]="true" [enableFilter]="false"
			[rowData]="veninfoadicfac" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsInfoAdic" [defaultColDef]="defaultColDefInfoAdic"
			[rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="true" (selected)="seleccioninfoadic($event)"
			[mostrarGuardar]="false"></app-aggridsaci>
	</p-tabPanel>
	<!-- GSRF -->
	<p-tabPanel header="Exportación / Inf. Adicional" [disabled]="botonNuevo && index<1 || expo">
		<div class="divgrups">
			<div class="p-grid">
				<!--LINEA 1-->
				<div class="p-col-1">
					<p style="text-align: center;">
						<p-checkbox name="group1" value="" label="" binary="{{chkactivo}}"
						[(ngModel)]="chkactivo" (click)="limpiaexport()"></p-checkbox>
					</p>
				</div>
				<div class="p-col-11">
					<span>Factura de exportación</span>
				</div>
				<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<h3 style="text-align: center;">Etiqueta</h3>
							</div>
							<div class="p-col-5">
								<h3 style="text-align: center;">Exportación</h3>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;comercioExterior&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="COMERCIOEXTERIOR"></label>
								<input class="frm" id="COMERCIOEXTERIOR" type="text"  pInputText maxlength="50" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].COMERCIOEXTERIOR" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;IncoTermFactura&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="INCOTERMFACTURA"></label>
								<input class="frm" id="INCOTERMFACTURA" type="text"  pInputText maxlength="50" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].INCOTERMFACTURA" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;lugarIncoTerm&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="LUGARINCOTERM"></label>
								<input class="frm" id="LUGARINCOTERM" type="text"  pInputText maxlength="300" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].LUGARINCOTERM" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;paisOrigen&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="PAISORIGEN"></label>
								<input class="frm" id="PAISORIGEN" type="text"  pInputText maxlength="5" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].PAISORIGEN" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;puertoEmbarque&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="PUERTOEMBARQUE"></label>
								<input class="frm" id="PUERTOEMBARQUE" type="text"  pInputText maxlength="300" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].PUERTOEMBARQUE" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;puertoDestino&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="PUERTODESTINO"></label>
								<input class="frm" id="PUERTODESTINO" type="text"  pInputText maxlength="300" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].PUERTODESTINO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;paisDestino&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="PAISDESTINO"></label>
								<input class="frm" id="PAISDESTINO" type="text" pInputText maxlength="5" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].PAISDESTINO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;paisAdquisicion&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="PAISADQUISICION"></label>
								<input class="frm" id="PAISADQUISICION" type="text"   pInputText maxlength="5" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].PAISADQUISICION" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;dirComprador&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="DIRCOMPRADOR"></label>
								<input class="frm" id="DIRCOMPRADOR" type="text"  pInputText value=" " maxlength="300" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].DIRCOMPRADOR" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;incoTermTotalSinImpuestos&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="INCOTERMTOTALSINIMPUESTOS"></label>
								<input class="frm" id="INCOTERMTOTALSINIMPUESTOS" type="text" pInputText  maxlength="50" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].INCOTERMTOTALSINIMPUESTOS" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;fleteInternacional&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="FLETEINTERNACIONAL"></label>
								<input class="frm" id="FLETEINTERNACIONAL" type="number" pInputText placeholder="0" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].FLETEINTERNACIONAL" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;seguroInternacional&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="SEGUROINTERNACIONAL"></label>
								<input class="frm" id="SEGUROINTERNACIONAL" type="number" pInputText  placeholder="0" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].SEGUROINTERNACIONAL" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;gastosAduaneros&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="GASTOSADUANEROS"></label>
								<input class="frm" id="GASTOSADUANEROS" type="number" pInputText  placeholder="0" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].GASTOSADUANEROS" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;gastosTransporteOtros&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="GASTOSTRANSPORTEOTROS"></label>
								<input class="frm" id="GASTOSTRANSPORTEOTROS" type="number" pInputText placeholder="0" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].GASTOSTRANSPORTEOTROS" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-2">
							</div>
							<div class="p-col-3">
								<p style="text-align: center;">&lt;formaPago&gt;</p>
							</div>
							<div class="p-col-5">
								<label for="FORMAPAGO"></label>
								<input class="frm" id="FORMAPAGO" type="number" placeholder="0"  pInputText maxlength="3" [disabled]="edita"
									[(ngModel)]="encfacService.encfacarray[indicador].FORMAPAGO" (keydown.enter)="setFocus($event)"
									(keydown)="botonesmodificar()" autocomplete="off">
							</div>
						</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
	<!-- <p-tabPanel header="Exportación Nuevo">
		
	</p-tabPanel> -->
	<p-tabPanel header="Exportación ATS" [disabled]="botonNuevo && index<1 || expo">
			<p-tabView>
				<p-tabPanel header="Cliente" [disabled]="expo" >
					<div class="divgrups" >
						<div class="p-grid">
							<div class="p-col-12">
								<span style="font-weight: bold">Especifique la información correspondiente al
									cliente</span>
							</div>
							<div class="p-col-3">
							</div>
							<div class="p-col-9">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-3">
												<span>Tipo Id. Cliente</span>
											</div>
											<div class="p-col-5"><input type="text" style="width: 100%"
												[disabled]="true"
												[(ngModel)]="encfacService.encfacarray[indicador].CLI_TIPOIDE"
												autocomplete="off">
										</div>
											<div class="p-col-4"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-3">
												<span>N° Identificación Cliente</span>
											</div>
											<div class="p-col-5"><input type="text" style="width: 100%"
													[disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].CLI_RUCIDE"
													autocomplete="off">
											</div>
											<div class="p-col-4"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-3">
												<span>¿Es parte relacionada?</span>
											</div>
											<div class="p-col-5"><input type="text" style="width: 100%"
													[disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].CLI_PARTEREL"
													autocomplete="off">
											</div>
											<div class="p-col-4"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-3">
												<span>Tipo de Cliente</span>
											</div>
											<div class="p-col-5"><input type="text" style="width: 100%"
													[disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].CLI_TIPO"
													autocomplete="off">
											</div>
											<div class="p-col-4"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-3">
												<span>Razón Social</span>
											</div>
											<div class="p-col-5"><input type="text" style="width: 100%"
													[disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].CLI_NOMBREXP"
													autocomplete="off">
											</div>
											<div class="p-col-4"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-12">
								<span style="font-weight: bold">Informacion de la Exportación</span>
							</div>
							<div class="p-col-3">
							</div>
							<div class="p-col-9">
								<div class="p-grid">
									<div class="p-col-12">
										<div class="p-grid">
											<div class="p-col-7">
												<span>Tipo de regimen fiscal del exterior:</span>
											</div>
											<div class="p-col-4">
												<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
													[options]="opcionesRegimen"
													[(ngModel)]="SELECTIPOREGI"
													(ngModelChange)="habilitarExpNuevoCliente()"
													></p-dropdown>
											</div>
											<div class="p-col-1"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-7">
												<span>País al que se realiza el pago en régimen general:</span>
											</div>
											<div class="p-col-1">
												<label for="codigoPaisRegimen"></label>
												<input  class="frm" id="codigoPaisRegimen" type="text"  pInputText 
												    (input)="botonesmodificar()" focusInput="booleanRegimen"
													style="width: 100%" [maxLength]="10"
													(keydown.enter)="abrirPaisesRegimen()"
													(keydown)="botonesmodificar()"
													[(ngModel)]="encfacService.encfacarray[indicador].codigoPaisRegimen" [disabled]="booleanRegimen"
													autocomplete="off" autofocus> 
											</div>
											<div class="p-col-1">
												<button type="button"
													style="background-image: url(../../../assets/images/iconos/buscar.png);"
													(click)="abrirPaisesRegimen()"
													(keydown)="botonesmodificar()"
													[disabled]="booleanRegimen"></button>
											</div>
											<div class="p-col-1">
												<span
													*ngIf="booleanRegimen === false">{{encfacService.encfacarray[indicador].nombrePaisSeleccionado}}</span>
											</div>
											<div class="p-col-2"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-7">
												<span>País al que se realiza el pago paraiso fiscal:</span>
											</div>
											<div class="p-col-1">
												<label for="codigoPaisParaiso"></label>
												<input class="frm" id="codigoPaisParaiso"  type="text" style="width: 100%"
													(input)="cambiarFoco($event)" [maxLength]="10"
													(keydown.enter)="abrirPaisesParaiso()"
													(keydown)="botonesmodificar()"
													[(ngModel)]="encfacService.encfacarray[indicador].codigoPaisParaiso" [disabled]="booleanParaiso"
													autocomplete="off">
											</div>
											<div class="p-col-1">
												<button type="button"
													style="background-image: url(../../../assets/images/iconos/buscar.png);"
													(click)="abrirPaisesParaiso()"
													(keydown)="botonesmodificar()"
													[disabled]="booleanParaiso"></button>
											</div>
											<div class="p-col-1">
												<span
													*ngIf="booleanParaiso === false">{{encfacService.encfacarray[indicador].nombrePaisSeleccionado}}</span>
											</div>
											<div class="p-col-2"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-7" style="text-align: left">
												<span>Denominación del régimen fiscal preferente o jurisdicción de
													menor imposición:</span>
											</div>
											<div class="p-col-4">
												<label for="DENOEXPCLI"></label>
												<input class="frm" id="DENOEXPCLI"
												type="text" style="width: 100%"
													(keydown.enter)="setFocus($event)"
													(keydown)="botonesmodificar()"
													[(ngModel)]="encfacService.encfacarray[indicador].DENOEXPCLI"
													[maxLength]="100" [disabled]="booleanRegimenP"
													autocomplete="off">
											</div>
											<div class="p-col-1"></div>
										</div>
									</div>
									<div class="p-col-12">
										<div class="p-grid" style="margin-top: -20px;">
											<div class="p-col-7" style="text-align: left">
												<span>País de residencia o establecimiento permanente a quien se
													efectua la exportación o ingreso del exterior</span>
											</div>
											<div class="p-col-1">
												<label for="paisResid"></label>
												<input class="frm"
												type="text" style="width: 100%" id="paisResid"
													(input)="cambiarFoco($event)"
													(keydown.enter)="abrirPaisesExportacion()"
													(keydown)="botonesmodificar()"
													[(ngModel)]="encfacService.encfacarray[indicador].PAISEFECEXP"
													[disabled]="booleanRegimenP1" [maxLength]="10"
													autocomplete="off">
											</div>
											<div class="p-col-1">
												<button type="button"
													style="background-image: url(../../../assets/images/iconos/buscar.png);"
													(click)="abrirPaisesExportacion()"
													(keydown)="botonesmodificar()"
													[disabled]="booleanRegimenP1"></button>
											</div>
											<div class="p-col-1">
												<span>{{encfacService.encfacarray[indicador].nombrePaisSeleccionado1}}</span>
											</div>
											<div class="p-col-1"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Refrendo" [disabled]="expo" >
					<div class="divgrups3" style="height: 400px;">
						<div class="p-grid" >
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-4">
										<span>Tipo de Exportación</span>
									</div>
									<div class="p-col-5">
										<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
											[options]="opcionesExportacion"
											[(ngModel)]="SELECTEXPORTACIONDE"
											(ngModelChange)="habilitarRefrendos()"></p-dropdown>
									</div>
									<div class="p-col-2"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-4">
										<span>Tipo de Ingreso del exterior</span>
									</div>
									<div class="p-col-5">
										<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
											[disabled]="booleanRefrendo" [options]="opcionesTipoIngresoExterior"
											[(ngModel)]="SELECTIPOINGRESO"></p-dropdown>
									</div>
									<div class="p-col-2"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-4">
										<span>El ingreso del exterior fue gravado con impuesto a la renta o uno
											similar, en el país en el que se obtuvo?</span>
									</div>
									<div class="p-col-3">
										<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
											(onChange)="habilitarImpuestoRenta()"
											[disabled]="booleanRefrendo" [options]="opcionesIngresoExterior"
											[(ngModel)]="SELECTINGGRAVADOEXT"
											(ngModelChange)="habilitarImpuestoRenta()"></p-dropdown>
									</div>
									<div class="p-col-4"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -5px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-4">
										<span>Valor del impuesto a la renta o impuesto similar pagado en el exterior
											por el ingreso obtenido</span>
									</div>
									<div class="p-col-4">
										<input class="frm" type="text" id="VALORIMPEXT" 
											[disabled]="booleanImpuestoRenta"
											(keydown.enter)="setFocus($event)"
											[(ngModel)]="encfacService.encfacarray[indicador].VALORIMPEXT" [maxLength]="32"
											pKeyFilter="pint" autocomplete="off">
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -1px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-4">
										<span>Valor FOB Valor del ingreso del Exterior</span>
									</div>
									<div class="p-col-4">
										<!-- <input class="frm" type="text" id="VALORFOB" autocomplete="off"
											(input)="cambiarFoco($event)" (keydown.enter)="cambiarFoco($event)" (keydown)="botonesmodificar()"
											[(ngModel)]="encfacService.encfacarray[indicador].VALORFOB" [maxLength]="32"
											appTwoDigitDecimaNumber> -->

										<input class="frm" id="VALORFOB" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										 type="text" style="width: 100%" (input)="cambiarFoco($event)"
											[(ngModel)]="encfacService.encfacarray[indicador].VALORFOB"
											[maxLength]="32" pKeyFilter="pint" autocomplete="off">
										
									</div>
									<div class="p-col-3"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -10px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-4">
										<span>Fecha de la Transacción</span>
									</div>
									<div class="p-col-2">
										<!-- <p-calendar (keydown.enter)="cambiarFoco($event)" id="FECHAEMBARQUE" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="encfacService.encfacarray[indicador].FECHAEMBARQUE"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown)="botonesmodificar()">
										</p-calendar> -->
										<p-calendar id="FECHAEMBARQUE" [inputStyle]="{width: '100%', height: '20px'}"
										[(ngModel)]="encfacService.encfacarray[indicador].FECHAEMBARQUE"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown.enter)="setFocus($event)"
										(keydown)="botonesmodificar()">
									</p-calendar>
									</div>
									<div class="p-col-5"></div>
								</div>
							</div>
						</div>
						<div class="p-grid" *ngIf="SELECTEXPORTACIONDE === '01'">
							<div class="p-col-1"></div>
							<div class="p-col-1">
								<span style="font-weight: bold">Refrendo</span>
							</div>
							<div class="p-col-10"></div>
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>N Documento de transporte</span>
									</div>
									<div class="p-col-3">
										<input class="frm" (keydown.enter)="setFocus($event)" type="text" id="doctran" style="width: 100%"
											(input)="cambiarFoco($event)"
											(keydown)="botonesmodificar()"
											[(ngModel)]="encfacService.encfacarray[indicador].DOCTRANSP" [maxLength]="13"
											autocomplete="off">
									</div>
									<div class="p-col-6"></div>
								</div>
							</div>
							<div class="p-col-12">
								<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Distrito</span>
									</div>
									<div class="p-col-4">
										<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
											[options]="opcionesDistrito"
											(keydown)="botonesmodificar()"
											[(ngModel)]="encfacService.encfacarray[indicador].DISTADUANERO"></p-dropdown>
									</div>
									<div class="p-col-5"></div>
								</div>
							</div>
							<div class="p-col-12">
								<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Año</span>
									</div>
									<div class="p-col-3">
										<input class="frm" id="ANIO" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										 style="width: 100%" (input)="cambiarFoco($event)"
											[(ngModel)]="encfacService.encfacarray[indicador].ANIO" type="number" pInputText onkeypress="if(this.value.length==4) return false"
											autocomplete="off">
									</div>
									<div class="p-col-6"></div>
								</div>
							</div>
							<div class="p-col-12">
								<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Régimen</span>
									</div>
									<div class="p-col-4">
										<p-dropdown [style]="{width: '100%', 'min-width': '100%'}"
											[options]="opcionesRegRefrendo"
											(keydown)="botonesmodificar()"
											[(ngModel)]="encfacService.encfacarray[indicador].REGIMEN"></p-dropdown>
									</div>
									<div class="p-col-5"></div>
								</div>
							</div>
							<div class="p-col-12">
								<div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Correlativo</span>
									</div>
									<div class="p-col-3">
										<input class="frm" id="CORRELATIVO" (keydown.enter)="setFocus($event)" 
										type="text" style="width: 100%" (input)="cambiarFoco($event)"
										(keydown)="botonesmodificar()"
											[(ngModel)]="encfacService.encfacarray[indicador].CORRELATIVO" [maxLength]="8"
											autocomplete="off">
									</div>
									<div class="p-col-6"></div>
								</div>
							</div>
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel header="Comprobante"  [disabled]="expo">
					<div class="divgrups3" style="height: 400px;">
						<div class="p-grid">
							<div class="p-col-12">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Tipo de Comprobante</span>
									</div>
									<div class="p-col-2">
										<span>01-FACTURA</span>
									</div>
									<div class="p-col-7"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Valor FOB del Comprobante Local</span>
									</div>
									<div class="p-col-2">
										<input class="frm" id="VALORFOBCOMPROBANTE" (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
										 type="text" style="width: 100%" (input)="cambiarFoco($event)"
											[(ngModel)]="encfacService.encfacarray[indicador].VALORFOBCOMPROBANTE"
											[maxLength]="32" pKeyFilter="pint" autocomplete="off">
									</div>
									<div class="p-col-7"></div>
								</div>
							</div>
							<div class="p-col-1"></div>
							<div class="p-col-2">
								<span style="font-weight: bold">Factura de Exportaciones</span>
							</div>
							<div class="p-col-9"></div>
							<div class="p-col-12" style="margin-top: -15px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
									</div>
									<div class="p-col-1">
										<div class="p-grid">
											<div class="p-col-12">
												<span>Establecimiento</span>
											</div>
											<div class="p-col-12">
												<input type="text" style="width: 100%" [disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].ESTABLECIMIENTO"
													autocomplete="off">
											</div>
										</div>
									</div>
									<div class="p-col-1">
										<div class="p-grid">
											<div class="p-col-12">
												<span>Pto de Emisión</span>
											</div>
											<div class="p-col-12">
												<input type="text" style="width: 100%" [disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].PUNTOEMISION"
													autocomplete="off">
											</div>
										</div>
									</div>
									<div class="p-col-1">
										<div class="p-grid">
											<div class="p-col-12">
												<span>Secuencial</span>
											</div>
											<div class="p-col-12">
												<input type="text" style="width: 100%" [disabled]="true"
													[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_SECACTUAL"
													autocomplete="off">
											</div>
										</div>
									</div>
									<div class="p-col-6"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>N° de Autorización</span>
									</div>
									<div class="p-col-3">
										<input type="text" style="width: 100%" [disabled]="true"
											[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_AUTORIZACION"
											autocomplete="off">
									</div>
									<div class="p-col-6"></div>
								</div>
							</div>
							<div class="p-col-12" style="margin-top: -15px;">
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-2">
										<span>Fecha de Emisión</span>
									</div>
									<div class="p-col-1">
										<p-calendar id="ENCFAC_FECHAEMISIONEXP" [inputStyle]="{width: '100%', height: '20px'}"
										[disabled]="true"
										[(ngModel)]="encfacService.encfacarray[indicador].ENCFAC_FECHAEMISION"
										[readonlyInput]="true" dateFormat="dd/mm/yy" (keydown)="botonesmodificar()">
										</p-calendar>
									</div>
									<div class="p-col-8"></div>
								</div>
							</div>
						</div>
					</div>
				</p-tabPanel>
			</p-tabView>
	</p-tabPanel>
	<!-- GSRF -->
	<p-tabPanel header="Estadísticas" [disabled]="disabledEstadisticas">
		<div class="p-grid">
			<!--LINEA 1-->
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-4">
								<button pButton (click)="mostrarEstadisticas()"
									style="background-image: url(../../../assets/images/iconos/cogwheel.png); width: 40px; height: 40px; background-color: transparent;"
									title="Generar estadísticas">
								</button>
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">VENTAS POR
									MESES</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<p-chart [options]="options" type="bar" [data]="dataVentasAcum" height="415px"></p-chart>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-6">
								<span style="font-size: 16px; font-weight: bold;">
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									PRODUCTOS MÁS VENDIDOS</span>
							</div>
							<div class="p-col-3">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="cantidad" inputId="orden1"
									(onClick)="orderByTopProd('cantidad')"></p-radioButton>
								<label> Cantidad </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="precio" inputId="orden2"
									(onClick)="orderByTopProd('total')"></p-radioButton>
								<label> Total </label>
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: -15px;">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detTopProductos" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsTopProductos"
							[defaultColDef]="defaultColTopProductos" [rowStyle]="rowStyleTopProd" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-12">
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">

					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-2">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DOCUMENTOS</span>
							</div>
							<div class="p-col-5">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 20px;">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos por Cobrar:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DXC" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocXCobrar" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocXCobrarPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Documentos Vencidos:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCVENCIDOS" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalDocVencidos" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalDocVencidosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Posfechados:</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_CHPOSFECHADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChPosfechados" autocomplete="off">
							</div>
							<div class="p-col-2">
								<span>{{totalChPosfechadosPorc}} %</span>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; float: right">Total:
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</div>
							<div class="p-col-2">
								<input id="TOTAL_DOCUMENTOS" type="text" pInputText readonly
									style="text-align: right; font-weight: bold" [(ngModel)]="totalDocumentos"
									autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Cheques Protestados:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_CHPROTESTADOS" type="text" pInputText readonly
									style="text-align: right" [(ngModel)]="totalChProtestados" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Pendientes:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDIDOPEND" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedPendientes" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold;">Pedidos Satisfechos:</span>
							</div>
							<div class="p-col-1">
								<input id="TOTAL_PEDSATISF" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="totalPedSatisfechos" autocomplete="off">
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-1">
							</div>
							<div class="p-col-3">
								<span style="font-weight: bold; ">Primera Factura: </span>
							</div>
							<div class="p-col-2">
								<input id="FechaPrimeraFact" type="text" pInputText readonly style="text-align: right"
									[(ngModel)]="fechaPrimeraFact" autocomplete="off">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-col-6">
				<div class="p-grid">
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-4">
							</div>
							<div class="p-col-4">
								<span style="font-size: 16px; font-weight: bold; text-align: center">DETALLE DE
									FACTURAS</span>
							</div>
							<div class="p-col-4">
							</div>
						</div>
					</div>
					<div class="p-col-12" style="margin-top: 10px;">
						<div class="p-grid">
							<div class="p-col-3">
							</div>
							<div class="p-col-2">
								<span>Ordenar por:</span>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="fecha" inputId="orden1"
									(onClick)="orderByDetFacturas('fecha')"></p-radioButton>
								<label> Fecha </label>
							</div>
							<div class="p-col-2" style="text-align: left">
								<p-radioButton name="orden" value="producto" inputId="orden2"
									(onClick)="orderByDetFacturas('producto')"></p-radioButton>
								<label> Producto </label>
							</div>
						</div>
					</div>
					<div class="p-col-12">
						<app-aggridsaci [width]="" [height]="'390'" [enableSorting]="true" [enableFilter]="true"
							[rowData]="detFacturas" [frameworkComponents]="frameworkComponents" [animateRows]="true"
							[rowSelection]="rowSelection" [columnDefs]="columnDefsDetFacturas"
							[defaultColDef]="defaultColDetFacturas" [rowStyle]="rowStyleDetFacts" [botones]="false"
							[singleClickEdit]="false">
						</app-aggridsaci>
					</div>
				</div>
			</div>
		</div>
	</p-tabPanel>
</p-tabView>


<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusqueda" [transitionOptions]="'0ms'" [modal]="false"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} " [focusOnShow]="true">
	<!-- <app-invbusqueda [arrayOptions]="types" [arregloConsulta]="arregloCons"
		(opcionSeleccionada)="manejarSeleccion($event)"></app-invbusqueda> -->
		<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>


<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>

<p-dialog header="Asiento Contable" [(visible)]="displayAsiContable" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width:'90vw', height:'120vh'}" [draggable]="false">
	<app-conencasil *ngIf="displayAsiContable" [NumAsientoBan]="asiento" [abiertoDesde]="'venencfac'"
		(signalSalir)="recibirSenalesConEncasil($event)"></app-conencasil>
</p-dialog>

<p-dialog header="Descuento Global" [(visible)]="displayDialogDescuento" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '35vw', height: '35vh'}">
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="aplicarDescuento()"
				style="background-image: url(../../../assets/images/iconos/visto.png);">
			</button>
		</div>
		<div class="p-col-12">
			<p-tabView>
				<p-tabPanel header="Tipo Descuento">
					<div class="p-grid">
						<div class="p-col-1"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="Por Valor" value="dscValor"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-3"></div>
						<div class="p-col-3">
							<p-radioButton name="opcionDescuento" label="En Porcentaje" value="dscPorcentaje"
								[(ngModel)]="dscSeleccionado" (ngModelChange)="cambioDescuento()"></p-radioButton>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-1"></div>
						<div class="p-col-4">
							<label for="DscValor"></label>
							<input id="DscValor" type="text" pInputText pKeyFilter="num" (input)="comprobarValor()"
								[(ngModel)]="valorDscValor" [disabled]="booleanValor" appThreeDigitDecimalNumber>
						</div>
						<div class="p-col-2"></div>
						<div class="p-col-4">
							<label for="DscPorcentaje"></label>
							<input id="DscPorcentaje" type="text" pInputText pKeyFilter="num"
								(focusout)="comprobarValor()" [(ngModel)]="valorDscPorcentaje"
								[disabled]="booleanPorcentaje" appTwoDigitDecimaNumber>
						</div>
						<div class="p-col-1"></div>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</p-dialog>

<p-dialog header="Pago de la Factura" [(visible)]="displayDialogPagoContado" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: 'auto'}" (onHide)="onHideEvent()">
	<app-pagocontado *ngIf="displayDialogPagoContado" [auxiliar]="auxiliar" [strNumFact]="strNumFact" [encfac]="encfacService.encfacarray[indicador]"
		(salir)="salirDialog()" [modulo]="'ventas'"></app-pagocontado>
</p-dialog>

<p-dialog header="Pago a Crédito" [(visible)]="displayDialogPagoCredito" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '500px'}">
	<div class="divgrups">
		<div class="ui-g ui-fluid">
			<div class="divgrupsdark">
				<div class="p-grid">
					<div class="p-col-1">
					</div>
					<div class="p-col-5">
						<button type="button" class="big"
							style="background-image: url(../../../assets/images/iconos/visto.png);"
							(click)="obtenerSeleccion()"></button>
					</div>
				</div>
			</div>
		</div>
		<div class="p-grid">
			<div class="p-col-3">
			</div>
			<div class="p-col-6">
				<div class="divgrups">
					<div class="p-grid">
						<div class="p-col-12">
							<p-radioButton id="FEC" name="pagcre" value="FEC" label="Fecha Vencimiento"
								[(ngModel)]="rbtFECCUO"></p-radioButton>
						</div>
						<div class="p-col-12">
							<p-radioButton id="CUO" name="pagcre" value="CUO" label="Cuotas" [(ngModel)]="rbtFECCUO">
							</p-radioButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Anular Factura" [(visible)]="displayDialogRazon" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '400px'}">
	<div class="divgrups">

		<div class="ui-g ui-fluid">
			<div class="p-grid">
				<div class="p-col-1">
					<span>Razón</span>
				</div>
				<div class="p-col-11"></div>
				<div class="p-col-12">
					<label for="RAZONANULAR"></label>
					<input class="frm" id="RAZONANULAR" type="text" pInputText [(ngModel)]="razonanular"
						autocomplete="off">
				</div>
				<div class="p-col-12">
					Debe ingresar la razón para anular la factura
				</div>
				<div class="p-col-8">
				</div>
				<div class="p-col-2">
					<button type="button" class="big"
						style="background-image: url(../../../assets/images/iconos/visto.png);"
						(click)="anularFactura()"></button>
				</div>
				<div class="p-col-2">
					<button type="button" class="big"
						style="background-image: url(../../../assets/images/iconos/borrar.png);"
						(click)="cancelarAnularFac()"></button>
				</div>

			</div>

		</div>
	</div>
</p-dialog>


<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Clave Supervisor" [(visible)]="displayDialogSupervisor" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideSupervisor()" [style]="{width: '40vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<div class="p-grid">
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="usuariosup" style="font-weight: bold">Usuario:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input pInputText id="usuariosup" [(ngModel)]="usuarioSup" placeholder="Ingrese el usuario">
				</div>
				<div class="p-col-1"></div>
				<div class="p-col-1"></div>
				<div class="p-col-4">
					<label for="clavesup" style="font-weight: bold">Clave:</label>
				</div>
				<div class="p-col-2"></div>
				<div class="p-col-4">
					<input type="password" pInputText id="clavesup" [(ngModel)]="claveSup"
						(keydown.enter)="validarClave()" placeholder="Ingrese la clave">
				</div>
				<div class="p-col-1"></div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog header="Ingreso de Seriales" [(visible)]="displayDialogSeriales" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-invtrnkardexotr *ngIf="displayDialogSeriales" [strNumFact]="strNumFact" [strFechaSer]="strFechaSer" [strTipoDoc]="'VEN'" [strTipoTrn]="'SA'"
		(cerrar)="cerrarSeriales()"></app-invtrnkardexotr>
</p-dialog>

<p-dialog header="Cuotas - Cliente" [(visible)]="displayDialogCuotas" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '80vw', height: 'auto'}">
	<app-cxcenccuota *ngIf="displayDialogCuotas" [auxiliar]="auxcuota"
		[auxNumFact]="encfacService.encfacarray[indicador].ENCFAC_NUMERO"
		[encfacdata]="encfacService.encfacarray[indicador]" (cerrar)="cerrarCuotas($event)"></app-cxcenccuota>
</p-dialog>

<p-dialog header="Lotes" [(visible)]="displayDialogLotes" [transitionOptions]="'0ms'" [modal]="true" appendTo="body"
	[style]="{width: '50vw', height: 'auto'}">
	<!-- 	[closable]="false" > 	-->
	<app-invtrnlote [auxiliar]="auxiliarlot" [detalle]="detfacSelected"
		[encabezado]="encfacService.encfacarray[indicador]" [cadena]="'FACVEN'" (salir)="cerrarLotes()">
	</app-invtrnlote>
</p-dialog>

<p-dialog header="Log de la Firma Electrónica" [(visible)]="displayDialogLog" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<label for="LOGFIRMA"></label>
			<textarea id="LOGFIRMA" rows="12" cols="100" pInputTextarea style="width: 100%; height: 400px;" 
				[(ngModel)]="logResultado" [disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>

<p-dialog header="Estados firma electrónica" [(visible)]="displayDialogEst" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '60vw', height: '60vh'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<label for="ESTFIRMA"></label>
			<textarea id="ESTFIRMA" rows="12" cols="100" pInputTextarea style="width: 100%; height: 230px;font-size: 16px;"
				[(ngModel)]="estfirma" [disabled]="true" maxlength="3000"></textarea>
		</div>
	</div>
</p-dialog>
<p-dialog header="Datos adicionales" [(visible)]="displayDialogDatosVentaArticulo" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '70vw', height: '300px'}">
	<app-datosventaarticulo [detalle]="detfacSelected" [encfac]="encfacService.encfacarray[indicador]">
	</app-datosventaarticulo>
</p-dialog>


<p-dialog header="Reportes" [(visible)]="displayDialogReportes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacimaecfngimpr [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
		[strNumDoc]="encfacService.encfacarray[indicador].ENCFAC_NUMERO" [strCampo]="'ENCFAC_NUMERO'"
		(cerrar)="cerrarReportes()"></app-sacimaecfngimpr>
</p-dialog>

<p-dialog header="Actualización de Datos" [(visible)]="displayDialogActualizacionFE" [transitionOptions]="'0ms'"
	[modal]="true" appendTo="body" [style]="{width: '45vw', height: 'auto'} ">
	<div class="p-grid">
		<div class="p-col-12">
			<button pButton (click)="actualizarDatos('C', encfacService.encfacarray[indicador].CLI_CODIGO)" style="background-image: url(../../../assets/images/iconos/guardar.png);
 					background-size: contain !important; width: 40px; height: 40px">
			</button>
		</div>
		<div class="p-col-12">
			<div class="divgrups">
				<div class="p-grid">
					<div class="p-col-4">
						<label for="TXTCORREO1" style="font-weight: bold">Correo Electrónico1:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<label for="TXTCORREO1"></label>
						<input class="frm" id="TXTCORREO1" type="text" pInputText [(ngModel)]="txtCorreo1"
							autocomplete="off">
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="TXTCORREO2" style="font-weight: bold">Correo Electrónico2:</label>
					</div>
					<div class="p-col-1"></div>
					<div class="p-col-6">
						<label for="TXTCORREO2"></label>
						<input class="frm" id="TXTCORREO2" type="text" pInputText [(ngModel)]="txtCorreo2"
							autocomplete="off">
					</div>
					<div class="p-col-2"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog header="Existencia por bodega" [(visible)]="displayDialogExistBod" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '500px'}">
	<app-existenciabodega *ngIf="displayDialogExistBod" [txtArtCodigo]="txtArtCodigo"
		[txtArtDescripcion]="txtArtDescripcion" [txtListaPrecio]="txtListaPrecio" [iva]="txtIva"
		[txtCliente]="txtObservacionRes" [txtBodegaDes]="txtBodegaRes" (signalSalir)="salirExistenciasBod()">
	</app-existenciabodega>
</p-dialog>

<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>	
</div>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'VENENCFAC'"
		[txtNumComprobante]="encfacService.encfacarray[indicador].ENCFAC_NUMERO"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>

<p-dialog  header="Reembolsos" [(visible)]="displayDialogReembolsos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '65vw', height: 'auto'}" (onHide)="verificarImportes()">
	<app-ventrnreembolsos [auxiliar]="auxiliarrem" [strNumFact]="strNumFact"  [ivavigente]="ivavigente" (cerrar)="cerrarReembolsos()">
	</app-ventrnreembolsos>
</p-dialog>

<!-- ///////////////////////nuevo vehiculo////////////////////// -->
<p-dialog header="Datos Vehículo" [(visible)]="displayvehiculo" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '85vw', height: '85vh'} " [focusOnShow]="true" appendTo="body" >
	
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-2">
            <span>Artículo</span>
        </div>
        <div class="p-col-10">
            <span>{{this.ART_CODIGO + ' - ' + this.ART_NOMBRE}}</span>
        </div>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>RAMV:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_RAMV"></label>
					<input class="frm" id="ART_RAMV" type="text" pInputText disabled 
					value="{{ART_RAMV}}">
                </div>
                <div class="p-col-1">
                    <span>Motor:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MOTOR"></label>
					<input class="frm" id="ART_MOTOR" type="text" pInputText disabled
					value="{{ART_MOTOR}}">
                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Modelo SRI:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MODELOSRI"></label>
					<input class="frm" id="ART_MODELOSRI" type="text" pInputText disabled
					value="{{ART_MODELOSRI}}">
                </div>
                <div class="p-col-1">
                    <span>Serie:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_SERIEV"></label>
					<input class="frm" id="ART_SERIEV" type="text" pInputText disabled
					value="{{ART_SERIEV}}">
                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Capacidad:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_CAPACIDAD"></label>
					<input class="frm" id="ART_CAPACIDAD" type="text" pInputText disabled
					value="{{ART_CAPACIDAD}}">
                </div>
                <div class="p-col-1">
                    <span>Cilidraje:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_CILINDRAJE"></label>
					<input class="frm" id="ART_CILINDRAJE" type="text" pInputText disabled
					value="{{ART_CILINDRAJE}}">

                </div>
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Color:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_COLOR"></label>
					<input class="frm" id="ART_COLOR" type="text" pInputText disabled
					value="{{ART_COLOR}}">
                </div>
                <div class="p-col-1">
                    <span>Año:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_ANIO"></label>
					<input class="frm" id="ART_ANIO" type="text" pInputText disabled
					value="{{ART_ANIO}}">
                </div>
                
            </div>

        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Marca:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_MARCAV"></label>
							<input class="frm" id="ART_MARCAV" type="text"  pInputText disabled
							value="{{ART_MARCAV}}">
                        </div>
                        <div class="p-col-7">
                            <label for="ART_MARVEH"></label>
							<input class="frm" id="ART_MARVEH" type="text" pInputText disabled
							value="{{ART_MARVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Tonelaje:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_TONELAJE"></label>
					<input class="frm" id="ART_TONELAJE" type="text" pInputText disabled
					value="{{ART_TONELAJE}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Clase:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_CLASE"></label>
							<input class="frm" id="ART_CLASE" type="text" pInputText disabled
							value="{{ART_CLASE}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_CLAVEH"></label>
							<input class="frm" id="ART_CLAVEH" type="text" pInputText disabled
							value="{{ART_CLAVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Número de ejes:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_NEJES"></label>
					<input class="frm" id="ART_NEJES" type="text" pInputText disabled
					value="{{ART_NEJES}}">
                </div>

            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Tipo de vehiculo:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_TIPOV"></label>
							<input class="frm" id="ART_TIPOV" type="text" pInputText disabled
							value="{{ART_TIPOV}}">
                        </div>
                        <div class="p-col-7">
                            <label for="ART_TIPVEH"></label>
							<input class="frm" id="ART_TIPVEH" type="text" pInputText disabled
							value="{{ART_TIPVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Número de ruedas:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_NRUEDAS"></label>
					<input class="frm" id="ART_NRUEDAS" type="text" pInputText disabled
					value="{{ART_NRUEDAS}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Combustible:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_COMBUSTIBLE"></label>
							<input class="frm" id="ART_COMBUSTIBLE" type="text" pInputText disabled
							value="{{ART_COMBUSTIBLE}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_COMVEH"></label>
							<input class="frm" id="ART_COMVEH" type="text" pInputText disabled
							value="{{ART_COMVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Fecha de fabricación:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_FFABRICA"></label>
					<input class="frm" id="ART_FFABRICA" pInputText disabled
					value="{{ART_FFABRICA}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Dispositivo:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_DISPOSITIVO"></label>
							<input class="frm" id="ART_DISPOSITIVO" type="text" pInputText disabled
							value="{{ART_DISPOSITIVO}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_DISVEH"></label>
							<input class="frm" id="ART_DISVEH" type="text" pInputText disabled
							value="{{ART_DISVEH}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Modelo agencia de tránsito:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_MODELOAT"></label>
					<input class="frm" id="ART_MODELOAT" type="text" pInputText disabled
					value="{{ART_MODELOAT}}">
                </div>
            </div>
        </div>

        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>País de origen:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_PORIGEN"></label>
							<input class="frm" id="ART_PORIGEN" type="text" pInputText disabled
							value="{{ART_PORIGEN}}">
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_P"></label>
							<input class="frm" id="ART_P" type="text" pInputText disabled
							value="{{ART_P}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <span>Detalle de pago:</span>
                </div>
                <div class="p-col-2">
                    <label for="ART_DPAGO"></label>
					<input class="frm" id="ART_DPAGO" type="text" pInputText disabled
					value="{{ART_DPAGO}}">
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Banco de Pago:</span>
                </div>
                <div class="p-col-5">
                    <div class="p-grid">
                        <div class="p-col-3">
                            <label for="ART_BANCO"></label>
							<input class="frm" id="ART_BANCO" type="text" pInputText disabled
							value="{{ART_BANCO}}">
                            
                        </div>
                        
                        <div class="p-col-7">
                            <label for="ART_NBANCO"></label>
							<input class="frm" id="ART_NBANCO" type="text" pInputText disabled
							value="{{ART_NBANCO}}">
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-grid">
						<div class="p-col-6">
							<p style="text-align: center;">
								<button class=button_largos type="button"
									style="background-image: url(../../../assets/images/iconspdv/boton-ver-imagen.png); width: 7em"
									(click)="verimagen(detfacSelected.DETFAC_CODIGO)"></button>
							</p>
						</div>
						<div class="p-col-6">
							<p style="text-align: center;">
								<button class=button_largos type="button"
									style="background-image: url(../../../assets/images/iconspdv/boton-largo-en-cargar.png); width: 7em"
									(click)="cargardatosveh()"></button>
							</p>
						</div>
						
					</div>
                </div>

            </div>
        </div>
    </div>
</div>
</p-dialog>

<p-dialog header="Documentos" [(visible)]="displayDialogDocumentosim" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentosim" [txtOrigen]="'Invmaearticulo'"
		[txtNumComprobante]="detfacSelected.DETFAC_CODIGO" (signalSalir)="recibirSenalesDocumentos($event)">
	</app-uploadfiles>
</p-dialog>
<p-dialog header="Pedidos Pendientes" [(visible)]="displayDialogpedidodpendientes" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '65vw', height: 'auto'}" >
	<app-venconsulped  [strNumFact]="'FACVEN'" (cerrar)="cerrarpedidospendientes()">
	</app-venconsulped>
</p-dialog>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="encfacService.spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	<p-dialog [(visible)]="encfacService.spin" [transitionOptions]="'0ms'" [modal]="true"  [closable]="false"
	[style]="{ opacity: 0.00}" position="top">
	</p-dialog>
</div>
<p-dialog header="Bases IVA" [(visible)]="displaybasesiva" [transitionOptions]="'0ms'" [modal]="false"
	[style]="{width: '55vw', height: '55vh'} " [focusOnShow]="true" appendTo="body" >

	<app-aggridsaci [width]="-800" [height]="-800" [enableSorting]="true" [enableFilter]="true"
			[rowData]="basesiva" [frameworkComponents]="frameworkComponents" [animateRows]="true"
			[rowSelection]="rowSelection" [columnDefs]="columnDefsBasesIva"
			[defaultColDef]="defaultColBasesIva" [rowStyle]="rowStyle" [botones]="false"
			[singleClickEdit]="false"></app-aggridsaci>



</p-dialog>
<!-- CDPJ -->
<p-dialog header="Formularios de Correos" [(visible)]="displayDialogfrmcorreos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '40vw', height: '400px'}">
	<app-sacifrmenviocorreos [auxiliar]="auxreporte" [strNombreForma]="strNombreForma"
	[strNumDoc]="encfacService.encfacarray[indicador].ENCFAC_NUMERO" [strCampo]="'ENCFAC_NUMERO'"
		[strCliProvCod]="encfacService.encfacarray[indicador].CLI_CODIGO" [CENREPDOCE]="CENREPDOCE"
		(cerrar)="cerrarfrmcorreos()"></app-sacifrmenviocorreos>
</p-dialog>
<!-- CDPJ -->